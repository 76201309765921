"use client";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { CardStack } from "../../card-stack/card-stack";
import { useTextDirectionContext } from "../../../helpers/TextDirectionContext";
import { useCardStackState } from "../../../helpers/useCardStackState";
import classNames from "classnames/bind";
import { useLayoutEffect, useRef } from "react";
import { waitForScrollConfig } from "../interactive-hero/utils/interactive-hero-utils";
import styles from "./athlete-quote-stack.module.css";
import { Athlete, AthleteType } from "../../card-stack/athlete/athlete";

const cx = classNames.bind(styles);

export interface AthleteQuoteStackProps {
  cards: AthleteType[];
  animated?: boolean;
}

export const AthleteQuoteStack = ({
  cards,
  animated,
}: AthleteQuoteStackProps) => {
  const textDirection = useTextDirectionContext();

  const { cardStackState, cardStackActions } = useCardStackState({
    rtl: textDirection === "rtl",
  });

  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (!animated) {
      return;
    }
    const ctx = gsap.context(() => {
      const rootElement = ref.current;
      const cardsStackElement = rootElement?.querySelector(
        "[data-card-stack=container]",
      );
      const cardsElement = rootElement?.querySelector(
        "[data-card-stack=cards-wrapper]",
      );
      const timeline = gsap.timeline();
      timeline.from(
        cardsStackElement ?? null,
        { duration: 0.4, opacity: 0, clearProps: "opacity" },
        0,
      );
      timeline.from(
        cardsElement ?? null,
        {
          duration: 0.6,
          y: "+=100",
          opacity: 0,
          clearProps: "opacity,transform",
        },
        0.5,
      );
      timeline.pause();
      const scrollTrigger = new ScrollTrigger({
        trigger: rootElement,
        start: "top 70%",
        animation: timeline,
        once: true,
      });
      scrollTrigger.disable();

      waitForScrollConfig().then(() => {
        scrollTrigger.enable();
      });
    });

    return () => ctx.revert();
  }, [animated]);

  return (
    <div className={cx("container")} ref={ref}>
      <div className={cx("inner")}>
        <CardStack
          cards={cards}
          cardStackState={cardStackState}
          isWide={true}
          cardStackActions={cardStackActions}
          hasNavigationDots={true}
          renderCard={({ card, allCards }) => (
            <Athlete card={card} allCards={allCards} />
          )}
        />
      </div>
    </div>
  );
};
